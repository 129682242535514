<template>
  <div class="page_content_wrap">
    <div class="overview_welcome text-center py-5" v-if="!isLoading && getUser">
      <div class="container">
        <h5 class="text-primary mb-3">
          <span class="font-size-lg">👋</span> Welcome to your free Retirement
          Risk Assessment!
        </h5>
        <h1>What are the true costs of retirement risks?</h1>
        <p class="font-size-lg">
          This personalized review will identify which risks may impact your
          retirement, how much they could cost, and when they're most likely to
          occur.
        </p>
      </div>
    </div>
    <div class="loader" v-if="isLoading || !getUser"></div>

    <div class="overview_step" v-if="!isLoading && getUser">
      <div class="container">
        <div class="row flex-md-row-reverse align-items-center py-4">
          <div class="col-sm-6">
            <div class="overview_step_img text-center">
              <img src="/images/step-new-1.png" class="img-fluid" alt="" />
            </div>
          </div>
          <div class="col-sm-6 mt-4 mt-md-0">
            <div class="overview_step_txt">
              <h5 class="btn btn-pastel-primary">STEP 1</h5>
              <h2 class="text-dark pt-2">
                Answer a few general retirement questions.
              </h2>
              <p class="font-size-lg">
                This helps us personalize your experience based on your answers.
              </p>
            </div>
          </div>
        </div>
        <div class="row flex-md-row-reverse align-items-center py-4">
          <div class="col-sm-6">
            <div class="overview_step_img text-center">
              <img src="/images/risk_yes_no-new.png" class="img-fluid" alt="" />
            </div>
          </div>
          <div class="col-sm-6 mt-4 mt-md-0">
            <div class="overview_step_txt">
              <h5 class="btn btn-pastel-primary">STEP 2</h5>
              <h2 class="text-dark pt-2">
                Review the potential risks you may face, and select the risks
                you’d like to plan for.
              </h2>
            </div>
          </div>
        </div>
        <div class="row flex-md-row-reverse align-items-center py-4">
          <div class="col-sm-6">
            <div class="overview_step_img text-center">
              <img src="/images/step3.png" class="img-fluid" alt="" />
            </div>
          </div>
          <div class="col-sm-6 mt-4 mt-md-0">
            <div class="overview_step_txt">
              <h5 class="btn btn-pastel-primary">STEP 3</h5>
              <h2 class="text-dark pt-2">
                See your results - including the potential costs of these risks,
                and a forecast of when they may occur.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="overview_step py-4" v-if="!isLoading && getUser">
      <div class="container">
        <div class="row">
          <div class="col-sm-4 offset-sm-4">
            <button class="btn btn-primary btn-lg btn-block" @click="nextPage">
              START NOW
              <i class="fas fa-arrow-right pl-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="overview_disclaimer py-2">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <p class="font-size-md text-center text-muted px-sm-5">
              *Pay nothing unless you decide to purchase some or<br />
              all of the insurance products suggested in the plan.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="overview_disclaimer py-5" v-if="!isLoading && getUser">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 offset-sm-1">
            <p class="font-size-sm text-center text-muted">
              Materials offered by Heyday Retirement, including tools, articles,
              ebooks, and workshops are designed to provide general information
              on the subjects covered. They are not intended to provide specific
              financial, legal or tax advice. Heyday markets insurance products
              and its representatives do not give investment, legal or tax
              advice. You are encouraged to consult your tax advisor, attorney,
              or investment advisor. By contacting us or submitting your contact
              information, using online tools, downloading booklets, or
              attending workshops, Heyday may refer you to our licensed
              insurance professionals who may contact you to offer a meeting to
              discuss how insurance services can help meet your retirement
              needs. You may withdraw your consent anytime. All Heyday authors,
              professors and educators are paid for their contributions. Their
              inclusion does not represent an endorsement of products. Copyright
              2020 Heyday Retirement. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="copyright_wrap border-top border-gray-200"
      v-if="!isLoading && getUser"
    >
      <p class="font-size-sm text-center text-muted m-0 py-5">
        Copyright {{ new Date().getFullYear() }} Heydayretirement.com All rights
        reserved.
      </p>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Screen1",
  data() {
    return {
      isLoading: true,
      from: null,
    };
  },
  methods: {
    ...mapActions("wizard", {
      updateProgress: "updateProgress",
    }),
    nextPage() {
      this.updateProgress({ step: 1, data: { riskLink: "risk.state" } });
      this.$router.push({ name: "risk.state" });
    },
  },
  computed: {
    ...mapGetters({
      getUser: "getUser",
    }),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from;
    });
  },
  mounted() {
    console.log("this.$router.prevRoute");
    console.log(this.from);

    if (this.from && this.from.name == null) {
      setTimeout(() => {
        console.log(this.getUser);
        this.isLoading = false;
        if (
          this.getUser &&
          this.getUser.riskLink != "" &&
          this.getUser.riskLink != "risk"
        ) {
          this.$router.push({ name: this.getUser.riskLink });
        }
      }, 1000);
    } else if (this.from && this.from.name && this.from.name == "dashboard") {
      this.isLoading = false;
      console.log(this.getUser);
      if (
        this.getUser &&
        this.getUser.riskLink != "" &&
        this.getUser.riskLink != "risk"
      ) {
        this.$router.push({ name: this.getUser.riskLink });
      }
    } else {
      this.isLoading = false;
    }
  },
};
</script>
