require('./bootstrap');

import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import myMixin from './mixins/mixin';
import { auth } from '@/utils/firebase.js';

import { identifyUser } from '@/utils/hubspot'
import { USER_LOCAL_STORAGE_KEY, STATE_REGION_LOCAL_STORAGE_KEY } from '@/utils/constants'
import '@/scss/style.scss'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)

import device from "vue-device-detector"
Vue.use(device)

Vue.config.productionTip = false;
Vue.prototype.$log = console.log;

Vue.component('progress-bar', require('./components/ProgressBar.vue').default);
Vue.component('errors-bar', require('./components/ErrorsBar.vue').default);

import vueJustgage from 'vue-justgage';
Vue.use(vueJustgage);

import Select2 from 'v-select2-component';
Vue.component('Select2', Select2);

import RangeSlider from 'vue-range-slider'
Vue.component('r-slider', RangeSlider);
import 'vue-range-slider/dist/vue-range-slider.css'

import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; 
Vue.component('lottie-player', require('lottie-vuejs/src/LottieAnimation.vue').default);

let Fire = new Vue()
window.Fire = Fire;

import { Form, HasError, AlertError } from 'vform'
window.Form = Form;

import { mapMutations } from "vuex";
let app
auth.onAuthStateChanged(user => {

  // console.log('onAuthStateChanged');
  // console.log(user);

  if (!app) {
    app = new Vue({
      router,
      store,
      mixins: [myMixin],
      methods: {
        ...mapMutations("wizard", ["SET_PROGRESS", 'SET_BACK_LINK'])
      },
      watch: {
        '$route': function(to, from) {
          this.SET_BACK_LINK(to.meta.backLink);  
          this.SET_PROGRESS(to.meta.progress);  
        }
      },
      render: (h) => h(App),
    }).$mount('#app');
  }

  if (user) {

    localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user))
    identifyUser(user.email)
		store.dispatch('setUserLoginData', user);

    // if(user.providerData.length) {
      if(window.location.pathname != '/') {
        store.commit('setLastPath', window.location.pathname);
      }
    // } else {
    //   store.commit('setLastPath', window.location.pathname);
    // }
  
  } else {
    localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
    localStorage.removeItem(STATE_REGION_LOCAL_STORAGE_KEY)
  }

});