<template>
  <div id="app">
    <Nav v-if="!['Login', 'LoginEmail', 'forgotPassword'].includes($route.name)"  />
    <router-view />
  </div>
</template>

<script>
import Nav from '@/components/Nav';

export default {
  components: {
    Nav
  }
}
</script>