<template>
  <div class="page_content_wrap">
    <div class="retirement_wrap py-3 mt-4" v-if="getUserLoginData">
      <div class="container">
        <div class="state_content_box mt-3 text-center">
          <h5 class="font-size-sm text-primary mb-2">
            Hi, {{ getUserLoginData.displayName }}
          </h5>
          <h2 class="text-dark">
            Complete your retirement income plan with the Heyday app.
          </h2>
        </div>
      </div>
    </div>
    <div class="loader" v-else></div>
    <div class="retirement-section pb-7">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-sm-12 col-md-6 col-lg-4 mb-md-0 mb-4">
            <div class="card retirement_card_box rounded-0 border h-100">
              <router-link
                :to="{ name: 'risk.home' }"
                class="hover-box text-decoration-none"
              >
                <div class="card-body">
                  <div class="retirement-inner-box">
                    <div class="retirement-img text-center">
                      <img
                        class="w-100"
                        src="/images/risk_yes_no.png"
                        alt="no-images"
                      />
                    </div>
                    <div class="recommendation-text text-dark text-center p-3">
                      <p class="font-size-sm text-primary font-weight-bold">
                        RISK MANAGEMENT APP
                      </p>
                      <h6 class="text-dark mb-3">
                        Determine the risks you may face in retirement.
                      </h6>
											<div class="btn btn-primary btn-lg btn-block">
														START NOW <i class="fas fa-arrow-right pl-1"></i>
											</div>
                    </div>
                  </div>
									<div class="progress-box" v-if="getUser">
                      <p class="text-muted font-size-sm mb-0 text-center">
                        {{
                          getUser.riskProgress == 100
                            ? "Complete"
                            : "In progress"
                        }}
                      </p>
                      <div class="progress rounded-0 mt-1">
                        <div
                          class="progress-bar"
                          :style="{'width': riskProgress+'%'}"
                          role="progressbar"
                          :aria-valuenow="riskProgress"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {{ riskProgress }}%
                        </div>
                      </div>
									</div>
                </div>
              </router-link>
            </div>
          </div>
         <div class="col-sm-12 col-md-6 col-lg-4 mb-md-0 mb-4">
            <div class="card retirement_card_box rounded-0 border h-100">
                <router-link :to="{name : 'calculatorHome'}" class="hover-box text-decoration-none">
                    <div class="card-body">
                        <div class="retirement-inner-box">
                            <div class="retirement-img text-center">
                                <img class="w-100" src="/images/artwork-2.png" alt="no-images" >
                            </div>
                            <div class="recommendation-text text-dark text-center p-2">
                                <p class=" font-size-sm text-primary font-weight-bold"> INCOME CALCULATOR</p>
                                <h6 class="text-dark mb-3">Customize your income plan based on your risks and goals.</h6>
																<div class="btn btn-primary btn-lg btn-block">
														START NOW <i class="fas fa-arrow-right pl-1"></i>
											</div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
          <!-- <div class="col-sm-12 col-md-6 col-lg-4 mb-md-0 mb-4">
            <div class="card rounded-0 border h-100 hover-show">
                <div class="card-body">
                    <div class="retirement-inner-box">
                        <div class="video-card shadow-none rounded-0 overlay overlay-primary overlay-20 py-6 py-md-6" style="background-image: url('images/video.png');">
                            <a href="https://player.vimeo.com/video/135857941" class="icon-circle icon-circle-lg bg-primary text-white hover-scale position-relative" data-fancybox="">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path d="M9.82866499,18.2771971 L16.5693679,12.3976203 C16.7774696,12.2161036 16.7990211,11.9002555 16.6175044,11.6921539 C16.6029128,11.6754252 16.5872233,11.6596867 16.5705402,11.6450431 L9.82983723,5.72838979 C9.62230202,5.54622572 9.30638833,5.56679309 9.12422426,5.7743283 C9.04415337,5.86555116 9,5.98278612 9,6.10416552 L9,17.9003957 C9,18.1765381 9.22385763,18.4003957 9.5,18.4003957 C9.62084305,18.4003957 9.73759731,18.3566309 9.82866499,18.2771971 Z" fill="#000000"></path>
                                </g>
                            </svg>
                            </a>
                        </div>
                        <router-link :to="{name : 'education.home'}" class="hover-video text-decoration-none">
                            <div class="recommendation-text text-dark text-center p-3">
                                <p class=" font-size-sm text-primary font-weight-bold">EDUCATIONAL PORTAL </p>
                                <h6 class="text-dark">Learn more about the products and strategies in your plan.</h6 class="text-black-50">
                            </div>
                            <div class="progress-box p-3">
                                <p class="text-muted font-size-sm mb-0">Completed</p>
                                <div class="progress rounded-0 mb-2 mt-1">
                                    <div class="progress-bar w-100 " role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">100%</div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div> -->
        </div>
      </div>
    </div>
    <contact-form></contact-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContactForm from "./ContactForm.vue";

export default {
  components: {
    ContactForm,
  },
  filters: {
    round: function (value) {
      if (!value) return "";
      return Math.round(value);
    },
  },
  computed: {
    riskProgressClass() {
      return `w-${this.getUser.riskProgress}`;
    },
    riskProgress() {
      return parseInt(this.getUser.riskProgress);
    },
    ...mapGetters({
      getUser: "getUser",
      getUserId: "getUserId",
    }),
    ...mapGetters({
      getUserLoginData: "getUserLoginData",
    }),
  },
  mounted() {
    console.log(this.getUser);
  },
};
</script>
