import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions';

let firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
}

// if (location.hostname === 'localhost') {
// // if (process.env.NODE_ENV === 'development') {
//     // use firebase emulator locally
//     firebase.firestore().useEmulator('localhost', 8081);
    // firebase.functions().useEmulator('localhost', 5001);
// }


// Get a Firestore instance
const app = firebase.initializeApp(firebaseConfig);
const functions = app.functions('us-central1');

// if (location.hostname === 'localhost') {
//     functions.useEmulator('localhost', 5001);
// }

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const usersCollection = db.collection('users')
const contactsCollection = db.collection('contacts')
const educationCollection = db.collection('educationProgress')
const calculatorData = db.collection('calculatorData')
const annuityEstimateHistory = db.collection('annuityEstimateHistory')

// collection references
const estimateAnnuityIncome = functions.httpsCallable('estimateAnnuityIncome')

const getContact = async (contactId) => {
  console.log(`Attempting to fetch contact with ID: ${contactId}`);
  try {
    const doc = await contactsCollection.doc(contactId).get();
    if (doc.exists) {
      return doc.data();
    } else {
        const data = { email: contactId, properties: [] }
        await contactsCollection.doc(contactId).set(data)
        console.log(`Contact with ID ${contactId} not found. Returning default object.`);
        return data
    }
  } catch (error) {
    console.error(`Error fetching contact with ID ${contactId}:`, error);
    const stub = await createContact({ email: contactId, properties: [] })
    console.log({stub})
    return stub
  }
};

// Update a contact by ID
const updateContact = async (contactId, data) => {
  console.log(`Attempting to update contact with ID: ${contactId} with data:`, data);
  try {
    await contactsCollection.doc(contactId).update(data);
    return { status: 'success' };
  } catch (error) {
    console.error(`Error updating contact with ID ${contactId}:`, error);
    throw { status: 'error', message: error.message };
  }
};

// Create a contact by ID
const createContact = async (data) => {
  console.log("Attempting to create a new contact with data:", data);
  try {
    const docRef = contactsCollection.doc(data.email);
    await docRef.set(data);
    return { status: 'success', id: docRef.id };
  } catch (error) {
    console.error("Error creating contact:", error);
    throw error;
  }
};


const  defaultProfile = (object) => {
    return Object.assign({
        name: '',
        email: '',
        picture: '',
        state: '',
        marital_status: '',
        age: '',
        emailVerified: false,
        riskLink: 'risk',
        riskProgress: 0,
        calculatorLink: 'calculator',
        calculatorProgress: 0,
        educationLink: 'education',
        educationProgress: 0,
        created: new Date().toISOString(),
        updated: new Date().toISOString()
    }, object);
}

export {
    defaultProfile,
    firebase,
    auth,
    getContact,
    updateContact,
    createContact,
    estimateAnnuityIncome,
    usersCollection,
    educationCollection,
    calculatorData,
    annuityEstimateHistory
}