import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth'
import wizard from './modules/wizard'
import education from './modules/education'
import calculator from './modules/calculator'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: auth,
		wizard: wizard,
    education: education,
    calculator: calculator
  }
});
