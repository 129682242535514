// import Errors from './../../modules/errors';
import Vue from 'vue'

const state = {
   progress: 0,
   progressAddOnEachCall: 100 / 11,
   risk: {},
   features: [
      {
         id: 1,
         image: "/images/steps_icon/steps_icon_1.svg",
         title: "Loss of Income",
         content: "I'm concerned about the sizable income drop-off at retirement.",
      },
      {
         id: 2,
         image: "/images/steps_icon/steps_icon_2.svg",
         title: "Salary Dependence",
         content: "One spouse is still working, which could hinder our plans for early retirement.",
      },
      {
         id: 3,
         image: "/images/steps_icon/steps_icon_3.svg",
         title: "Early Retirement Income Shortfall",
         content: "Do I have to wait to retire? My Social Security income doesn't start for another few years.",
      },
      {
         id: 4,
         image: "/images/steps_icon/steps_icon_4.svg",
         title: "Cancelled Plans",
         content: "I'm worried about having to put off plans, such as travel, to make sure I'm not overspending.",
      },
      {
         id: 5,
         image: "/images/steps_icon/steps_icon_5.svg",
         title: "Economic Uncertainty",
         content: "I need to plan for potential economic changes, such as tax increases or market volatility.",
      },
      {
         id: 6,
         image: "/images/steps_icon/steps_icon_6.svg",
         title: "Running Out of Money",
         content: "With so many unknowns, I find myself worried that I may run out of money later.",
      },
      {
         id: 7,
         image: "/images/steps_icon/steps_icon_7.svg",
         title: "Rising Expenses (Inflation)",
         content: "I may have enough for today, but I'm concerned how inflation will impact my spending power later on.",
      },
      {
         id: 8,
         image: "/images/steps_icon/steps_icon_8.svg",
         title: "Emergency Fund for Unknown Expenses",
         content: "I'd like to make sure I maintain an emergency fund, and possibly continue adding to it.",
      },
      {
         id: 9,
         image: "/images/steps_icon/steps_icon_9.svg",
         title: "Helping Kids and Grandkids",
         content: "I'm concerned that I may need to help support my adult child or grandchildren.",
      },
      {
         id: 10,
         image: "/images/steps_icon/steps_icon_10.svg",
         title: "Caring for Aging Parents",
         content: "I have a parent/parents that may need assistance, requiring additional time or financial commitment.",
      },
      {
         id: 11,
         image: "/images/steps_icon/steps_icon_11.svg",
         title: "Healthcare Costs Up to Age 65",
         content: "I'm concerned about how I should plan for healthcare costs before I reach age 65.",
      },
      {
         id: 12,
         image: "/images/steps_icon/steps_icon_12.svg",
         title: "Healthcare Costs After Age 65",
         content: "After age 65, I want to be prepared for healthcare costs not covered by Medicare.",
      },
      {
         id: 13,
         image: "/images/steps_icon/steps_icon_13.svg",
         title: "Uncovered Prescription Costs",
         content: "I want to make sure I have enough to afford any needed prescriptions that aren't covered by Medicare.",
      },
      {
         id: 14,
         image: "/images/steps_icon/steps_icon_14.svg",
         title: "Dental Care",
         content: "I may need to prepare for potentially large dental care costs.",
      },
      {
         id: 15,
         image: "/images/steps_icon/steps_icon_15.svg",
         title: "Home Maintenance and Repairs",
         content: "I anticipate some large home maintenance costs at some point in retirement.",
      },
      {
         id: 16,
         image: "/images/steps_icon/steps_icon_16.svg",
         title: "Loss of a Spouse",
         content: "I want to have a financial plan in place in case a spouse passes.",
      },
      {
         id: 17,
         image: "/images/steps_icon/steps_icon_17.svg",
         title: "Aging at Home",
         content: "Besides healthcare, I want to plan for other costs associated with aging in place.",
      },
      {
         id: 18,
         image: "/images/steps_icon/steps_icon_18.svg",
         title: "Long-Term Care",
         content: "I am concerned about the potential cost of Long-Term Care.",
      },
      {
         id: 19,
         image: "/images/steps_icon/steps_icon_19.svg",
         title: "Home Healthcare",
         content: "I plan on staying home as long as possible, with unskilled healthcare help.",
      },
      {
         id: 20,
         image: "/images/steps_icon/steps_icon_20.svg",
         title: "Heirs",
         content: "I want my retirement income plan to include leaving money for kids, grandkids, and/or charities.",
      },
   ],
   state: '',
   survey: {
      marital_status: '',
      are_you_retired: '',
      retirement_age: 21,
      personal_finances_attitude: '',
      my_top_retirement_concerns: '',
      age: 60,
      industry: ''
   },
   disposableIncomes: [],
   fixedIncomes: [],
   expences: [],
   backLink: '/'
}

const getters = {
   getBackLink: state => state.backLink,
   getProgress: state => state.progress,
   getRisk: state => state.risk,
   getSurvey: state => state.survey,
   getDisposableIncomes: state => state.disposableIncomes,
   getFixedIncomes: state => state.fixedIncomes,
   getExpences: state => state.expences
}

const actions = {
   setProgress: ({ commit }, payload) => {
      commit('SET_PROGRESS', payload);
   },
   updateProgress: ({ commit, state, dispatch, rootState}, payload) => {

      let last = state.progress;
      let total = last;
      if (payload.step == 1) {
         total = total + state.progressAddOnEachCall;
      } else if (payload.step == 0) {
         total = total + (state.progressAddOnEachCall / 20);
      }
      let newProgress = total <= 100 ? total : 100;

      if(typeof payload.data != 'undefined') {
         let userObj = payload.data;
         userObj.riskProgress = newProgress;

         dispatch('updateUserProfile', userObj, { 
            root: true 
         });
      }
      
      // console.log('setProgress');
      // console.log(state.progress);
      // console.log(payload);
      // console.log(rootState);
      // commit('UPDATE_PROGRESS', newProgress);
   },
   updateRiskStatus: ({ commit }, payload) => {
      commit('UPDATE_RISK_STATUS', payload);
   },
}

const mutations = {
   UPDATE_PROGRESS: (state, payload) => {

      // let last = state.progress;
      // let total = last;
      // if (payload.step == 1) {
      //    total = total + state.progressAddOnEachCall;
      // } else {
      //    total = total + (state.progressAddOnEachCall / 21);
      // }
      // state.progress = total <= 100 ? total : 100;

      state.progress = payload;

   },
   SET_PROGRESS: (state, progress) => {

      Vue.set(state, 'progress', progress);
      // state.progress = progress;
   },
   SET_BACK_LINK: (state, payload) => {
      Vue.set(state, 'backLink', payload);
   },
   UPDATE_RISK_STATUS: (state, payload) => {
      state.risk[payload.key] = payload.value;
      state.features[payload.index].status = payload.status;
   },
   UPDATE_SURVEY_FIELD: (state, payload) => {
      state.survey[payload.key] = payload.value;
   },
   SET_SURVEY: (state, payload) => {

      if (payload.marital_status == 'Divorced' || payload.marital_status == 'Widowed' || payload.marital_status == 'Other') {
         payload.marital_status = 'Single';
      }

      if (payload.marital_status == 'Other') {
         payload.are_you_retired = 'Retired';
      }

      Vue.set(state, 'survey', payload);
      // state.survey = payload;
   },
   SET_DISPOSABLE_INCOME: (state, payload) => {
      Vue.set(state, 'disposableIncomes', payload);
   },
   REMOVE_DISPOSABLE_INCOME: (state, obj) => {
      let disposableIncomes = [...state.disposableIncomes];
      disposableIncomes.splice(obj.index, 1);
      Vue.set(state, 'disposableIncomes', disposableIncomes);
   },
   ADD_NEW_DISPOSABLE_INCOME: (state, payload) => {
      let disposableIncomes = [...state.disposableIncomes, payload];
      Vue.set(state, 'disposableIncomes', disposableIncomes);
   },
   SET_FIXED_INCOMES: (state, payload) => {
      Vue.set(state, 'fixedIncomes', payload);
   },
   ADD_NEW_FIXED_INCOME: (state, payload) => {
      let fixedIncomes = [...state.fixedIncomes, payload];
      Vue.set(state, 'fixedIncomes', fixedIncomes);
   },
   REMOVE_FIXED_INCOME: (state, obj) => {
      let fixedIncomes = [...state.fixedIncomes];
      fixedIncomes.splice(obj.index, 1);
      Vue.set(state, 'fixedIncomes', fixedIncomes);
   },
   SET_EXPENCES: (state, payload) => {
      Vue.set(state, 'expences', payload);
   },
   ADD_NEW_EXPENCE: (state, payload) => {
      let expences = [...state.expences, payload];
      Vue.set(state, 'expences', expences);
   },
   REMOVE_EXPENCE: (state, obj) => {
      let expences = [...state.expences];
      expences.splice(obj.index, 1);
      Vue.set(state, 'expences', expences);
   },
}

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
}