// import Errors from './../../modules/errors';
import Vue from 'vue'
import * as fb from '@/utils/firebase'

const state = {
    progress: 0,
    progressAddOnEachCall : 100 / 5,
    backLink: '/',
    progression: []
}

const getters = {
    getBackLink: state => state.backLink,
    getProgress: state => state.progress,
    getRisk: state => state.risk,
    getSurvey:  state => state.survey,
    getDisposableIncomes:  state => state.disposableIncomes,
    getFixedIncomes: state => state.fixedIncomes,
    getExpences: state => state.expences
}

const actions = {
    getEducationProgress({ commit, dispatch }) {
        fb.educationCollection.doc(fb.auth.currentUser.uid)
        .get()
        .then(snap => {
            if(snap && snap.exists) {
                const progression = [];
                snap.forEach(doc => {
                  progression.push({ [doc.id]: doc.data() });
                });
                commit('SET_PROGRESSION', progression);
            } else {
                alert('Not Found');
                // dispatch('createEducationProgress');
            }
        });
    },
    createEducationProgress({ commit }, educationProgressionId) {
        // set user education progress
        // fb.educationCollection.add({
        //     userId : fb.auth.currentUser.uid,
        //     videoCompletedAt: new Date().toISOString(),
        //     created: new Date().toISOString(),
        //     updated: new Date().toISOString()
        // }, { merge: true });
        // let defaultProgression = { 'user_id' : user.uid };
        // commit('SET_PROGRESSION', progression);
    },
    saveVideoCompleted({ commit, dispatch }, educationProgressionId) {

    },
    saveQuizAnswered({ commit, dispatch }, educationProgressionId) {

    },
    saveUnderstandingSignOff({ commit, dispatch }, educationProgressionId) {

    },
    createProductSignOff({ commit, dispatch }, user) {

    },
    setProgress: ({commit}, payload) => {
        commit('SET_PROGRESS', payload);     
    },
    updateProgress: ({commit}, payload) => {
        commit('UPDATE_PROGRESS', payload);     
    },
    updateRiskStatus: ({commit}, payload) => {
        commit('UPDATE_RISK_STATUS', payload);
    },
}

const mutations = {
    SET_PROGRESSION: (state, progression) => {
        Vue.set(state, 'progression', progression);
    },
    UPDATE_PROGRESS: (state, payload) => {
        let last = state.progress;
        let total = last;
        if(payload == 1) {
            total = total + state.progressAddOnEachCall;    
        } else {
            total = total + ( state.progressAddOnEachCall / 20);
        }
        state.progress = total <= 100 ? total : 100;
    },
    SET_PROGRESS: (state, progress) => {

        Vue.set(state, 'progress', progress);
        // state.progress = progress;
    },
    SET_BACK_LINK: (state, payload) => {
        Vue.set(state, 'backLink', payload);
    },
    UPDATE_RISK_STATUS: (state, payload) => {
        state.risk[payload.key] = payload.value;
        state.features[payload.index].status = payload.status;
    },
    UPDATE_SURVEY_FIELD: (state, payload) => {
        state.survey[payload.key] = payload.value;
    },
    SET_SURVEY: (state, payload) => {

        if(payload.marital_status == 'Divorced' || payload.marital_status == 'Widowed' || payload.marital_status == 'Other') {
            payload.marital_status = 'Single';
        }

        if(payload.marital_status == 'Other') {
            payload.are_you_retired = 'Retired';
        }
        
        Vue.set(state, 'survey', payload);
        // state.survey = payload;
    },
    SET_DISPOSABLE_INCOME: (state, payload) => {
        Vue.set(state, 'disposableIncomes', payload);
    },
    REMOVE_DISPOSABLE_INCOME: (state, obj) => {
        let disposableIncomes = [...state.disposableIncomes];
        disposableIncomes.splice(obj.index, 1);
        Vue.set(state, 'disposableIncomes', disposableIncomes);
    },
    ADD_NEW_DISPOSABLE_INCOME: (state, payload) => {
        let disposableIncomes = [...state.disposableIncomes, payload];
        Vue.set(state, 'disposableIncomes', disposableIncomes);
    },
    SET_FIXED_INCOMES: (state, payload) => {
        Vue.set(state, 'fixedIncomes', payload);
    },
    ADD_NEW_FIXED_INCOME: (state, payload) => {
        let fixedIncomes = [...state.fixedIncomes, payload];
        Vue.set(state, 'fixedIncomes', fixedIncomes);
    },
    REMOVE_FIXED_INCOME: (state, obj) => {
        let fixedIncomes = [...state.fixedIncomes];
        fixedIncomes.splice(obj.index, 1);
        Vue.set(state, 'fixedIncomes', fixedIncomes);
    },
    SET_EXPENCES: (state, payload) => {
        Vue.set(state, 'expences', payload);
    },
    ADD_NEW_EXPENCE: (state, payload) => {
        let expences = [...state.expences, payload];
        Vue.set(state, 'expences', expences);
    },
    REMOVE_EXPENCE: (state, obj) => {
        let expences = [...state.expences];
        expences.splice(obj.index, 1);
        Vue.set(state, 'expences', expences);
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}