import Vue from 'vue'
import { auth, usersCollection, defaultProfile } from '@/utils/firebase.js'
import router from '@/router/index'

const state = {
    user_id: null,
    user: null,
    userLoginData: null,
    isLoggedIn: false,
    lastPath: 'Login'
}

const getters = {
    isUserLoggedIn: state => state.isLoggedIn,
    getUserId: state => state.user_id,
    getUserLoginData: state => state.userLoginData,
    getUser: state => state.user,
}

const actions = {
    setUser({ commit }, user) {
        commit('setUser', user)
    },
    setUserLoginData({ commit, dispatch }, user) {
        commit('setUserId', user.uid); 
        dispatch('createUserProfile', user)
        commit('setUserLoginData', user)
        
    },
    createUserProfile({ commit }, user) {
        console.log('createUserProfile');
        // // create user profile in firebase db
        usersCollection.doc(user.uid).get().then(document => {
            console.log('user exists =>' + document.exists);
            let doc = null;
            if (document && !document.exists) {
                doc = defaultProfile({
                    name: user.displayName, 
                    email: user.email,
                    emailVerified: user.emailVerified,
                    picture: user.photoURL
                });
                commit('setUser', doc);
                usersCollection.doc(user.uid).set(doc, { merge: true })
                .then(document => {
                    console.log('usersCollection');
                    console.log(document.data());
                    if (doc) {
                        Vue.set(state, 'isLoggedIn', true);
                    } else {
                        Vue.set(state, 'isLoggedIn', false);
                    }
                }).catch(err => {

                });
            } else {
                doc = document.data();
                commit('setUser', doc);
                if (doc) {
                    Vue.set(state, 'isLoggedIn', true);
                } else {
                    Vue.set(state, 'isLoggedIn', false);
                }
            }
           
        });
    },
    updateUserProfile({ commit, state }, userObj) {
        let user = {...state.user};
       
        userObj.update = new Date().toISOString();
        userObj.riskProgress = Math.round(userObj.riskProgress);
        
        if(user.riskProgress == 100) {
            delete userObj.riskProgress;
            delete userObj.riskLink;
        }

        let updatedUser = Object.assign(user, userObj);
        commit('setUser', updatedUser);

        usersCollection.doc(state.user_id).set(userObj, { merge: true })   
    },
    async login({ dispatch }, form) {
        // sign user in
        const { user } = await auth.signInWithEmailAndPassword(form.email, form.password)

        // fetch user profile and set in state
        dispatch('setUserLoginData', user)
    },
    async signup({ dispatch }, form) {
        // sign user up
        const { user } = await auth.createUserWithEmailAndPassword(form.email, form.password)

        // fetch user profile and set in state
        dispatch('setUserLoginData', user)
    },
    async logout({ commit }) {
        // log user out
        await auth.signOut()
        // clear user data from state
        commit('setUser', null);
        commit('setUserLoginData', null);
        commit('setLastPath', '/');
        // redirect to login view
        router.push({ name : 'Login'});
    },
}

const mutations = {
    setUserId(state, uid) {
        state.user_id = uid;
    },
    setUser(state, user) {
        console.log('setUser');
        console.log(user);
        Vue.set(state, 'user', user);
    },
    setUserLoginData(state, val) {
        state.userLoginData = val;
    },
    setLastPath(state, val) {
        Vue.set(state, 'lastPath', val);
    },
}

export default {
    //   namespaced: true,
    state,
    getters,
    actions,
    mutations,
}