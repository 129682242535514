import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/dashboard/Dashboard.vue';
import Screen1 from '../views/risk/Screen1.vue';
import { auth } from '@/utils/firebase'
import store from '@/store'

Vue.use(VueRouter);

function guard(to, from, next){
  console.log(auth.currentUser)
  if (auth.currentUser) {
  // if(store.state.auth.loggedIn) {
      next(); 
  } else {
      store.commit('setLastPath', to.path);
      next('/login');
  }
}

function guest(to, from, next){
  // const user = firebase.auth().currentUser;
  console.log(auth.currentUser)
  if (auth.currentUser) {
			next('/')
  } else {
		next();
  }
}

let riskProgressAddOnEachCall = store.state.wizard.progressAddOnEachCall;
let educationProgressAddOnEachCall = store.state.education.progressAddOnEachCall;

const routes = [
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: guard,
    meta: { showProgress: false, backLink : '/'  }
  },
  {
    path: '/loginEmail',
    name: 'LoginEmail',
		beforeEnter: guest,
    component: () => import(/* webpackChunkName: "loginEmail" */ '../views/auth/LoginEmail.vue'),
    meta: { showProgress: false }
  },
  {
    path: '/login',
    name: 'Login',
		beforeEnter: guest,
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue'),
    meta: { showProgress: false}
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () => import(/* webpackChunkName: "ForgotPassword" */ '../views/auth/ForgotPassword.vue'),
    beforeEnter: guest,
    meta: { showProgress: false}
  },
  {
    path: '/risk',
    name: 'risk',
    component:  () => import(/* webpackChunkName: "risk" */ '@/views/risk/index.vue'),
    beforeEnter: guard,
    children: [
      {
        path: '/',
        name: 'risk.home',
        component: () => import(/* webpackChunkName: "Screen1" */ '../views/risk/Screen1.vue'),
        meta: { showProgress: true, progress : 0 * riskProgressAddOnEachCall, backLink : 'dashboard' }
      },
      {
        path: 'state',
        name: 'risk.state',
        component: () => import(/* webpackChunkName: "Screen2" */ '../views/risk/Screen2.vue'),
        meta: { showProgress: true, progress : 1 * riskProgressAddOnEachCall, backLink : 'risk.home' }
      },
      {
        path: 'survey-bio',
        name: 'risk.surveyBio',
        component: () => import(/* webpackChunkName: "Screen3" */ '../views/risk/Screen3.vue'),
        beforeEnter: guard,
        meta: { showProgress: true, progress : 2 * riskProgressAddOnEachCall, backLink : 'risk.state'  }
      },
      {
        path: 'survey-concern',
        name: 'risk.surveyConcern',
        component: () => import(/* webpackChunkName: "Screen4" */ '../views/risk/Screen4.vue'),
        beforeEnter: guard,
        meta: { showProgress: true, progress : 3 * riskProgressAddOnEachCall, backLink : 'risk.surveyBio'  }
      },
      {
        path: 'risk-assessment-overview',
        name: 'risk.assessmentOverview',
        component: () => import(/* webpackChunkName: "Screen5" */ '../views/risk/Screen5.vue'),
        beforeEnter: guard,
        meta: { showProgress: true, progress : 4 * riskProgressAddOnEachCall, backLink : 'risk.surveyConcern'   }
      },
      {
        path: 'risk-assessment-activity',
        name: 'risk.slider',
        component: () => import(/* webpackChunkName: "Screen6" */ '../views/risk/Screen6.vue'),
        beforeEnter: guard,
        meta: { showProgress: true, progress : 5 * riskProgressAddOnEachCall, backLink : 'risk.assessmentOverview'   }
      },
      {
        path: 'expennses',
        name: 'risk.expennses',
        component: () => import(/* webpackChunkName: "Screen8" */ '../views/risk/Screen8.vue'),
        beforeEnter: guard,
        meta: { showProgress: true, progress : 6 * riskProgressAddOnEachCall, backLink : 'risk.slider'   }
      },
      {
        path: 'monthly-disposable-income',
        name: 'risk.disposableIncome',
        component: () => import(/* webpackChunkName: "Screen9" */ '../views/risk/Screen9.vue'),
        beforeEnter: guard,
        meta: { showProgress: true, progress : 7 * riskProgressAddOnEachCall, backLink : 'risk.expennses'   }
      },
      {
        path: 'fixed-income',
        name: 'risk.fixedIncome',
        component: () => import(/* webpackChunkName: "Screen10" */ '../views/risk/Screen10.vue'),
        beforeEnter: guard,
        meta: { showProgress: true, progress : 8 * riskProgressAddOnEachCall, backLink : 'risk.disposableIncome'   }
      },
      {
        path: 'monthly-expenses',
        name: 'risk.monthlyExpence',
        component: () => import(/* webpackChunkName: "Screen11" */ '../views/risk/Screen11.vue'),
        beforeEnter: guard,
        meta: { showProgress: true, progress : 9 * riskProgressAddOnEachCall, backLink : 'risk.fixedIncome'   }
      },
      {
        path: 'review',
        name: 'risk.review',
        component: () => import(/* webpackChunkName: "Screen12" */ '../views/risk/Screen12.vue'),
        beforeEnter: guard,
        meta: { showProgress: true, progress : 10 * riskProgressAddOnEachCall, backLink : 'risk.monthlyExpence'   }
      },
      {
        path: 'reviewing',
        name: 'risk.reviewLoader',
        component: () => import(/* webpackChunkName: "Loader" */ '../views/risk/Loader.vue'),
        beforeEnter: guard,
        meta: { showProgress: true, progress : 11 * riskProgressAddOnEachCall, backLink : 'risk.monthlyExpence'   }
      },
      {
        path: 'results',
        name: 'risk.results',
        component: () => import(/* webpackChunkName: "Screen13" */ '../views/risk/results/Screen13.vue'),
        beforeEnter: guard,
        meta: { showProgress: true, progress : 12 * riskProgressAddOnEachCall, backLink : 'risk.review'   }
      },
      {
        path: 'planning-recommendation',
        name: 'risk.planningRecommendation',
        component: () => import(/* webpackChunkName: "Screen14" */ '../views/risk/Screen14.vue'),
        beforeEnter: guard,
        meta: { showProgress: false, backLink : 'risk.results'  }
      }
    ]
  },
  {
    path: '/education',
    name: 'education',
    component:  () => import(/* webpackChunkName: "education" */ '@/views/education/index.vue'),
    beforeEnter: guard,
    children: [
      {
        path: '/',
        name: 'education.home',
        component: () => import(/* webpackChunkName: "education-home" */ '@/views/education/Home.vue'),
        meta: { showProgress: true, progress : 1 * educationProgressAddOnEachCall, backLink : 'dashboard' }
      },
      {
        path: 'annuity',
        name: 'education.annuity',
        component: () => import(/* webpackChunkName: "annuity" */ '@/views/education/Annuity.vue'),
        meta: { showProgress: true, progress : 1 * educationProgressAddOnEachCall, backLink : 'education.home' }
      },
      {
        path: 'basic-annuity',
        name: 'education.basic-annuity',
        component: () => import(/* webpackChunkName: "basic-annuity" */ '@/views/education/BasicAnnuity.vue'),
        meta: { showProgress: true, progress : 2 * educationProgressAddOnEachCall, backLink : 'education.annuity' }
      },
      {
        path: 'regular-income',
        name: 'education.regular-income',
        component: () => import(/* webpackChunkName: "regular-income" */ '@/views/education/RegularIncome.vue'),
        meta: { showProgress: true, progress : 3 * educationProgressAddOnEachCall, backLink : 'education.basic-annuity' }
      },
      {
        path: 'receive-money',
        name: 'education.receive-money',
        component: () => import(/* webpackChunkName: "receive-money" */ '@/views/education/ReceiveMoney.vue'),
        meta: { showProgress: true, progress : 4 * educationProgressAddOnEachCall, backLink : 'education.regular-income' }
      },
      {
        path: 'two-special',
        name: 'education.two-special',
        component: () => import(/* webpackChunkName: "two-special" */ '@/views/education/TwoSpecial.vue'),
        meta: { showProgress: true, progress : 5 * educationProgressAddOnEachCall, backLink : 'education.receive-money' }
      },
    ]
  },
  {
    path: '/calculator',
    name: 'calculatorHome',
    component: () => import(/* webpackChunkName: "calculatorHome" */ '../views/calculator/index.vue'),
    beforeEnter: guard,
    meta: { showProgress: false, backLink : 'dashboard'  },
    children: [
     
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// navigation guard to check for logged in users
// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

//   if (requiresAuth && !auth.currentUser) {
//     next('/login')
//   } else {
//       if($routerHistory.hasPrevious()) {
//         next($routerHistory.previous().path);
//       } else {
//         next()
//       }
    
//   }
// })

export default router;
