const myMixin = {
    methods: {
      
    },
    mounted() {
      console.log('hello')
    },
}
  
export default myMixin
  