export const trackPageView = pathname => {
    var _hsq = (window._hsq = window._hsq || [])
    _hsq.push(['setPath', pathname])
    _hsq.push(['trackPageView'])
  }
  
  export const identifyUser = (email, extraInfo = {}) => {

    var _hsq = (window._hsq = window._hsq || [])
  
    _hsq.push([
      'identify',
      {
        email,
        ...extraInfo
      }
    ])
  }
  