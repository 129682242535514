// import Errors from './../../modules/errors';
import Vue from 'vue'
import { CALC_LIST_KEY, CALC_CHART_RESULT_KEY, CALC_ANNUITY_HISTORY } from '@/utils/constants'
import { calculatorData, annuityEstimateHistory } from '@/utils/firebase.js'

const state = {
    progress: 0,
    backLink: '/',
    progressAddOnEachCall: 100 / 12,
    selection: {},
    chartData: [],
    annuityHistory: {}
}

const getters = {
    getBackLink: state => state.backLink,
    getProgress: state => state.progress,
    getSelection: state => state.selection,
    getChartData: state => state.chartData,
    getAnnuityHistory: state => state.annuityHistory,
}

const actions = {
    setProgress: ({ commit }, payload) => {
        commit('SET_PROGRESS', payload);
    },
    updateProgress: ({ commit }, payload) => {
        commit('UPDATE_PROGRESS', payload);
    },
    addItem: ({ commit, state }, payload) => {

        let selection = { ...state.selection };
        selection[payload.data.id] = {
            range: payload.data.range,
            amount: payload.data.amount,
        };

        calculatorData.doc(payload.uid).set({
            selection: selection,
            updated: new Date().toISOString()
        }, { merge: true }).then(() => {
            commit('ADD_ITEM', selection);
        });

    },
    resetCalculator({ commit}, uid) {
				annuityEstimateHistory.doc(uid).delete();
        calculatorData.doc(uid).delete();
				commit('SET_SELECTION', {});
				commit('SET_ANNUITY_HISTORY', {});
    },
    removeItem: ({ commit, state }, payload) => {

        let selection = { ...state.selection };
        if (selection[payload.item_id]) {
            delete selection[payload.item_id];
        }

        calculatorData.doc(payload.uid).set({
            selection: selection,
            updated: new Date().toISOString()
        }, { merge: true }).then(() => {
            commit('REMOVE_ITEM', selection);
        });
        
    },
    updateAnnuityHistory : ({ commit, state }, payload) => {
        // let annuityHistory = [ ...state.annuityHistory];
        // annuityHistory.unshift(payload.result);
				// state.annuityHistory = payload.result;
        annuityEstimateHistory.doc(payload.uid).set({
            history: payload.result,
            updated: new Date().toISOString()
        }, { merge: true }).then(() => {
            commit('SET_ANNUITY_HISTORY', payload.result);
        });
    }
}

const mutations = {
    UPDATE_PROGRESS: (state, payload) => {

        let last = state.progress;
        let total = last;
        if (payload == 1) {
            total = total + state.progressAddOnEachCall;
        } else {
            total = total + (state.progressAddOnEachCall / 20);
        }

        state.progress = total <= 100 ? total : 100;
    },
    SET_PROGRESS: (state, progress) => {

        Vue.set(state, 'progress', progress);
        // state.progress = progress;
    },
    SET_BACK_LINK: (state, payload) => {
        Vue.set(state, 'backLink', payload);
    },
    SET_SELECTION: (state, payload) => {
        Vue.set(state, 'selection', payload);
    },
    ADD_ITEM: (state, payload) => {
        localStorage.setItem(CALC_LIST_KEY, JSON.stringify(payload));
        Vue.set(state, 'selection', payload);
    },
    REMOVE_ITEM: (state, payload) => {
        localStorage.setItem(CALC_LIST_KEY, JSON.stringify(payload));
        Vue.set(state, 'selection', payload);
    },
    SET_CHART_DATA: (state, payload) => {
        localStorage.setItem(CALC_CHART_RESULT_KEY, JSON.stringify(payload.chartData));
        Vue.set(state, 'chartData', payload.chartData);
    },
    UPDATE_HISTORY: (state, payload) => {
        // let annuityHistory = [ ...state.annuityHistory];
        // annuityHistory.unshift(payload.result);

        // calculatorData.doc(payload.uid).set({
        //     history: payload.result,
        //     created: new Date().toISOString(),
        //     updated: new Date().toISOString()
        // }, { merge: true });

        // localStorage.setItem(CALC_ANNUITY_HISTORY, JSON.stringify(annuityHistory));
        // Vue.set(state, 'annuityHistory', annuityHistory);

        Vue.set(state, 'annuityHistory', payload);
    },
    SET_ANNUITY_HISTORY: (state, payload) => {
        Vue.set(state, 'annuityHistory', payload);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}