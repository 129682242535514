
<template>
<div class="progress rounded-0" style="height: 6px;">
    <div class="progress-bar" role="progressbar" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100" :style="{
            width: progress + '%',
      }"></div>
</div>
</template>


<script>
export default {
  props: {
    progress: Number
  }
};
</script>
