export const STATE_REGION_LOCAL_STORAGE_KEY = 'STATE_REGION'
export const USER_LOCAL_STORAGE_KEY = 'AUTH_USER'
export const FROM_REVIEW_PARAM_KEY = 'from-review'

export const HUBSPOT_DISPOSABLE_INCOME_LIST_KEY = 'disposable_income_list'
export const HUBSPOT_EXPENSES_LIST_KEY = 'expenses_list'
export const HUBSPOT_FIXED_INCOME_LIST_KEY = 'fixed_income_list'

export const DOES_NOT_APPLY_VALUE = "Doesn't apply to me"
export const APPLIES_TO_ME_VALUE = 'Applies to me'


export const CALC_LIST_KEY = 'calculator_data'
export const CALC_CHART_RESULT_KEY = 'calculator_chart_data'
export const CALC_ANNUITY_HISTORY = 'calculator_annuity_history'