<template>
<div class="plannin_wrap py-7">
	<div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="mt-3 text-center text-white">
                    <h3 class="mb-3">Contact us for live, world-class, personal service.</h3>
                    <h1 class="mb-3">
                        <a class="text-white" href="tel:1-844-4-0000"><i class="fas fa-phone-volume mr-2"></i>1-844-4-HEYDAY</a>
                    </h1>
                    <h4 class="mb-3">
                        <a class="text-white" href="mailto:info@heydayretirement.com"><i class="far fa-envelope mr-2"></i>info@heydayretirement.com</a>
                    </h4>
                    <p class="">Main Office: 7807 Baymeadows Road East, S.402 Jacksonville, Fl 32256</p>
                </div>
            </div>
            <!-- <div class="col-xl-6">
                <div class="write-form">
                    <div class="write-box">   
                    </div>
                    <form>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <input type="text" class="form-control" id="" placeholder="Name">
                            </div>
                            <div class="form-group col-md-6">
                                <input type="text" class="form-control" id="" placeholder="Last Name">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <input type="email" class="form-control" id="" placeholder="Email">
                            </div>
                            <div class="form-group col-md-6">
                            <input type="number" class="form-control" id="" placeholder="Phone">
                            </div>
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" id="" rows="3" placeholder="Message"></textarea>
                        </div>
                        <button type="submit" class="btn btn-primary btn-lg btn-block mt-4 mb-2 shadow-none">NEXT <i class="fal fa-arrow-circle-right ml-2"></i></button>
                    </form>
                </div>
            </div> -->
        </div>
	</div>
</div>
</template>

<script>
export default {

}
</script>
