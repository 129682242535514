
<template>
  <div class="header bg-white shadow-light-sm position-fixed w-100">
    <div class="header_nav_wrap">
      <div class="row">
        <div class="col">
          <div class="header_nav_back"  v-if="!['dashboard'].includes($route.name)" >
            <router-link :to="{ name : backLink }" ><i class="far fa-arrow-left"></i></router-link>
          </div>
        </div>
        <div class="col">
          <div class="header_nav_logo text-center py-1">
            <router-link to="/">
              <img src="../assets/images/heyday-dark.svg" alt="" />
            </router-link>
          </div>
        </div>
        <div class="col">
          <div class="header_nav_action text-right">
            <div class="dropdown">
              <div
                class="dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </div>
              <div
                class="dropdown-menu dropdown-menu-right shadow-light-sm"
                aria-labelledby="dropdownMenuButton"
                style=""
              >
                <router-link
                  class="dropdown-item"
                  to="login"
                  v-if="!$store.getters.isUserLoggedIn"
                  ><i class="fal fa-user"></i> Sign in</router-link
                >
                <a
                  class="dropdown-item"
                  href="javascript:void(0);"
                  @click="logoutLocal"
                  v-if="$store.getters.isUserLoggedIn"
                  ><i class="fal fa-user"></i> Sign Out</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <progress-bar :progress="progress" v-if="$route.meta.showProgress" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Nav",
   computed: {
    ...mapGetters("wizard", {
      progress: "getProgress",
      backLink: "getBackLink"
    })
  },
  methods: {
	  ...mapActions({
      logout : 'logout'
    }),
    logoutLocal() {
      this.logout();
      if(this.$drift) {
        this.$drift.reset();
        this.$drift.hide();
      }
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
