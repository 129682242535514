


<template>
<div class="alert alert-danger" role="alert" v-if="errors !== 'undefined' && errors.length">
    <li :key="index" v-for="(error, index) in errors">
        {{ error }}
    </li>
</div>
</template>


<script>
export default {
  props: {
    errors: Array
  }
};
</script>
